import React, { Component } from "react"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "./index.css"
import Slide from "./slide"

export default class EspecialidadesSlider extends Component {
  state = {
    activeSlide: 0,
    activeSlide2: 0,
    especialidades: [
      {
        title: "Sala de espera",
        image:
          "https://www.medelita.com/media/wysiwyg/blog/featured-image-wait-room.jpg",
        description: "Descripción completa",
      },
      {
        title: "Estacionamiento",
        image:
          "http://bocapalmbeachsealcoating.com/wp-content/uploads/2017/01/Parking-Lot.jpg",
        description: "Descripción completa",
      },
      {
        title: "Consultorio",
        image:
          "https://img10.naventcdn.com/avisos/18/00/53/47/89/62/720x532/83637644.jpg",
        description: "Descripción completa",
      },
    ],
  }
  render() {
    const { especialidades } = this.state
    const settings = {
      customPaging: function(i) {
        return (
          <a>
            <img src={especialidades[i].image} />
            {especialidades[i].title}
          </a>
        )
      },
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      arrows: false,
      infinite: true,
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      afterChange: current => this.setState({ activeSlide2: current }),
    }
    return (
      <div>
        <Slider {...settings}>
          {especialidades.map(especialidad => {
            return <Slide {...especialidad}></Slide>
          })}
        </Slider>
      </div>
    )
  }
}
