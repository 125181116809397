import React from "react";

import { Link } from "gatsby";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout/index";
import SEO from "../components/utils/seo";
import Slider from "../components/slider";

import "./index.css";
import Especialidades from "../components/especialidades";

class indexPage extends React.Component {
  updateDivHeight = () => {
    const container = document.querySelector("#bienvenido > div:first-of-type");
    console.log(container.style);
    container.style.height =
      container.children[0].clientHeight +
      container.children[0].offsetTop +
      "px";
  };

  componentDidMount() {
    this.updateDivHeight();
    window.onresize = this.updateDivHeight;
  }

  render() {
    return (
      <Layout>
        <SEO title="Inicio"></SEO>
        <div className="container main-page">
          <section className="index-section" id="hero">
            <div className="slide">
              <div className="details">
                <h2>Medicina de calidad</h2>
                <p>Conoce las especialidades que tenemos disponibles para ti</p>
                <Link to="#bienvenido" className="button">
                  Leer más{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      class="heroicon-ui"
                      d="M9.3 8.7a1 1 0 0 1 1.4-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.4-1.4l3.29-3.3-3.3-3.3z"
                    />
                  </svg>
                </Link>
                <div></div>
              </div>
              <GatsbyImage
                image={getImage(
                  this.props.data.sliderFirstImage.childImageSharp
                )}
              ></GatsbyImage>
              <div className="links">
                <div className="container">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        class="heroicon-ui"
                        d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
                      />
                    </svg>
                    <h3>
                      <Link to="#bienvenido">Conócenos más</Link>
                    </h3>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        class="heroicon-ui"
                        d="M13.04 14.69l1.07-2.14a1 1 0 0 1 1.2-.5l6 2A1 1 0 0 1 22 15v5a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2h5a1 1 0 0 1 .95.68l2 6a1 1 0 0 1-.5 1.21L9.3 10.96a10.05 10.05 0 0 0 3.73 3.73zM8.28 4H4v2a14 14 0 0 0 14 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 0 1-1.3.46 12.04 12.04 0 0 1-6.02-6.01 1 1 0 0 1 .46-1.3l2.26-1.14L8.28 4zm7.43 5.7a1 1 0 1 1-1.42-1.4L18.6 4H16a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V5.41l-4.3 4.3z"
                      />
                    </svg>
                    <h3>
                      Llama al <bold>7030 0386</bold>{" "}
                    </h3>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        class="heroicon-ui"
                        d="M5.64 16.36a9 9 0 1 1 12.72 0l-5.65 5.66a1 1 0 0 1-1.42 0l-5.65-5.66zm11.31-1.41a7 7 0 1 0-9.9 0L12 19.9l4.95-4.95zM12 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                      />
                    </svg>
                    <h3>
                      <Link to="/contacto">Visítanos</Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="index-section" id="bienvenido">
            <div>
              <div className="image-container">
                {" "}
                <GatsbyImage
                  image={getImage(this.props.data.firstImage.childImageSharp)}
                ></GatsbyImage>
              </div>
              <div className="image-container">
                {" "}
                <GatsbyImage
                  image={getImage(this.props.data.secondImage.childImageSharp)}
                ></GatsbyImage>
              </div>
            </div>
            <div>
              <h3>
                <span className="black">Bienvenido a</span>Especialidades
                Médicas Tepepan
              </h3>
              <p>
                Nos caracterizamos por ser una unidad que cuenta con médicos de
                Alta Especialidad Certificados. Contamos con más de 27
                especialidades y nos encontramos en una avenida principal de
                fácil acceso.
              </p>
              <Link className="button" to="/#instalaciones">
                Conoce nuestras instalaciones
              </Link>
            </div>
          </section>
          <section id="instalaciones" className="index-section">
            <Slider></Slider>
          </section>
          <section id="especialidades" className="index-section">
            <Especialidades></Especialidades>
          </section>
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    sliderFirstImage: file(relativePath: { eq: "slider-first-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
    firstImage: file(relativePath: { eq: "first-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    secondImage: file(relativePath: { eq: "second-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`;

export default indexPage;
