import React, { Component } from "react"
import Especialidad from "./especialidad"

import "./index.css"

const specialties = [
  {
    title: "Alergología",
    description: `<p>Los Alergólogos son médicos que se especializan en el estudio, diagnóstico y tratamiento de los trastornos del sistema inmunológico, específicamente aquellos debidos a hiper-sensibilidad (alergias) así como también las enfermedades relacionadas con inmuno-deficiencias.</p><p>El alergólogo está capacitado para evaluar los diferentes aspectos de las enfermedades alérgicas que pueden afectar a distintos sistemas del organismo (piel, aparato digestivo, aparato respiratorio, sistema cardiovascular, etc.). Suele ocurrir que un mismo paciente presente rinitis, conjuntivitis, asma, dermatitis y alergia alimentaria. El alergólogo puede realizar un diagnóstico y tratamiento integral.</p>`,
  },
  {
    title: "Angiología",
    description: `<p>La angiología es la rama de la medicina que se encarga de estudiar las enfermedades vasculares, es decir, las enfermedades que afectan a las venas, las arterias y a los vasos linfáticos. La cirugía vascular se encarga de la corrección quirúrgica de las enfermedades vasculares.</p>`,
  },
  {
    title: "Cardiología",
    description: `<p>La cardiología es la rama de la medicina que se encarga del estudio, diagnóstico y tratamiento de las enfermedades del corazón y del aparato circulatorio.
    </p><p>También se puede consultar a un cardiólogo para saber más sobre los factores de riesgo cardiovascular y averiguar las medidas que pueden tomarse para mejorar la salud cardiovascular. 
    </p>`,
  },
  {
    title: "Dermatología",
    description: `<p>La dermatología es una especialidad de la medicina que se ocupa del conocimiento y estudio de la piel humana y de las enfermedades que la afectan. Esta especialidad también se preocupa de la prevención de las enfermedades y de la preservación o la recuperación de la normalidad cutánea así como de la dermocosmética que se dedica a la higiene, a la apariencia y protección de la piel.</p>`,
  },
  {
    title: "Endocrinología",
    description: `<p>Rama de la medicina que se especializa en el diagnóstico y tratamiento de trastornos del sistema endocrino, que incluye las glándulas y órganos que elaboran hormonas. Estos trastornos incluyen diabetes, infertilidad, y problemas tiroideos, suprarrenales y de la hipófisis.</p>`,
  },
  {
    title: "Gastroenterología",
    description: `<p>La gastroenterología es el estudio de la función normal y las enfermedades del esófago, estómago, intestino delgado, colon y recto, páncreas, vesícula biliar, conductos biliares e hígado. Implica una comprensión detallada de la acción normal (fisiología) de los órganos gastrointestinales, lo que incluye el movimiento del material a través del estómago y del intestino (motilidad), la digestión y absorción de nutrientes en el cuerpo, la eliminación de residuos procedentes del sistema y la función del hígado como órgano digestivo. Incluye trastornos comunes e importantes como los pólipos del colon y el cáncer, la hepatitis, el reflujo gastroesofágico (acidez estomacal), úlcera péptica, colitis, enfermedades de la vesícula biliar y del tracto biliar, problemas nutricionales, Síndrome de Intestino Irritable (SII) y pancreatitis. En esencia, toda la actividad normal y las enfermedades de los órganos digestivos son parte del estudio de gastroenterología.</p>`,
  },
  {
    title: "Geriatría",
    description: `<p>La geriatría es la rama de la medicina que se preocupa de los problemas y enfermedades de los adultos mayores, cómo prevenirlas y manejarlas, y del proceso de envejecer. No sólo del aspecto médico, sino también de aspectos sicológicos y sociales que habitualmente acompañan este proceso.
    </p><p>Los geriatras son médicos expertos en el cuidado de los adultos mayores, tienen especial conocimiento de enfermedades que comúnmente aquejan a los ancianos, como:</p>
    <ul><li>Demencia.</li>
    <li>Caídas.</li>
    <li>Incontinencia urinaria.</li>
    <li>Osteoporosis.</li>
    <li>Depresión.</li>
    <li>Entienden la interacción entre el envejecer y otras enfermedades crónicas y degenerativas.</li>
    </ul>`,
  },
  {
    title: "Ginecología y Obstetricia ",
    description: `<p>La ginecología es la especialidad de la <span class="underline">medicina</span> dedicada al cuidado del sistema reproductor femenino. Los ginecólogos, por lo tanto, son los especialistas que atienden las cuestiones vinculadas al útero, la vagina y los ovarios.
    </p><p>Mientras que la obstetricia, se ocupa del embarazo, el parto y el puerperio.
    </p>`,
  },
  {
    title: "Hematología",
    description: `<p>La hematología es la especialidad médica que se ocupa del estudio, diagnóstico, tratamiento y prevención de las enfermedades de la sangre y los órganos que participan en su producción, como son la médula ósea, el bazo o los ganglios, entre otros. Asimismo , se ocupa de los componentes de la sangre (glóbulos rojos, eritrocitos, plaquetas, leucocitos, hemoglobina, plasma, etc.) y de sus características (mecanismo de coagulación, etc.)
    </p><p>Entre las numerosas enfermedades de que se ocupa la hematología cabe destacar las siguientes:
    </p><ul><li>Enfermedades oncológicas:
    <ul><li>Leucemia.</li>
        <li>Linfomas.</li>
        <li>Mielomas.</li></ul></li>
    <li>Trombosis.</li>
    <li>Enfermedades hemorrágicas, como la hemofilia.</li>
    <li>Síndrome mielodisplásico.</li>
    <li>Anemia.</li>
    <li>Neutropenia.</li>
    <li>Trombocitopenia.</li></ul>
    `,
  },
  {
    title: "Medicina del deporte",
    description: `<p>La medicina del deporte es la especialidad médica que estudia los efectos del ejercicio, del deporte y, en general, de la actividad física, en el organismo humano, desde el punto de vista de la prevención y tratamiento de las enfermedades y lesiones.</p><p> La labor del especialista en medicina del deporte no solo es tratar y rehabilitar las lesiones, lo más importante es prevenirlas, realizando un control médico-deportivo haciendo con esto que la vida deportiva del atleta dure por más tiempo manteniendo su nivel deportivo y en el caso de personas que realizan ejercicio físico, mejorar su rendimiento laboral, familiar y escolar.
    </p>`,
  },
  {
    title: "Medicina Física y Rehabilitación",
    description: `<p>Especialidad que se ocupa de la evaluación, tratamiento y seguimiento de niños y adultos con enfermedades del Sistema Muscular, Esquelético y Neurológico, que produzcan dolor y/o algún grado de limitación funcional, ya sea transitoria o permanente.</p>
    <p>Estas enfermedades van desde cuadros leves sin invalidez, a grandes discapacidades con episodios de dolor agudo de columna, músculos, tendones y articulaciones en general.</p>
    `,
  },
  {
    title: "Medicina General",
    description: `<p>El médico general es el profesional de la medicina que cuenta con los conocimientos y las destrezas necesarias para diagnosticar y resolver con tratamiento médico y con procedimientos sencillos la mayoría de los padecimientos que el ser humano sufre en su vida, desde niño hasta la vejez, con acciones frecuentemente realizadas en el consultorio.</p>`,
  },
  {
    title: "Medicina interna",
    description: `<p>Medicina Interna es una especialidad médica que se dedica a la atención integral del adulto enfermo, enfocada al diagnóstico y el tratamiento no quirúrgico de las enfermedades que afectan a sus órganos y sistemas internos, y a su prevención.</p>`,
  },
  {
    title: "Nefrología",
    description: `<p>Los nefrólogos diagnostican, tratan y supervisan el manejo de muchos trastornos que afectan a los riñones y las vías urinarias, incluyendo fallo renal, presión arterial alta, enfermedades renales heredadas, cálculos renales, infecciones de las vías urinarias y anormalidades en la orina tales como presencia de sangre y proteína.</p>`,
  },
  {
    title: "Neonatología",
    description: `<p>Los especialistas en neonatología tienen preparación especial que se necesita para evaluar y tratar los problemas médicos de recién nacido;  está específicamente preparado para manejar las situaciones más complejas y de alto riesgo.</p>
    <p>Si su bebé nació prematuro o con una enfermedad, lesión o defecto de nacimiento serio, un especialista en neonatología podría estar presente al momento del parto y durante el cuidado subsiguiente del recién nacido.</p>
    `,
  },
  {
    title: "Neumología",
    description: `<p>La neumología es la <span class="underline">especialidad médica</span> encargada del estudio de las <span class="underline">enfermedades</span> del <span class="underline">aparato respiratorio</span> y centra su campo de actuación en el diagnóstico, tratamiento y prevención de las enfermedades del <span class="underline">pulmón</span>, la <span class="underline">pleura</span> y el <span class="underline">mediastino</span>.</p>`,
  },
  {
    title: "Neumopediatría",
    description: `<p>Los neumólogos pediátricos diagnostican, tratan y supervisan a niños con afecciones respiratorias y pulmonares desde el nacimiento hasta los 21 años de edad
    </p><p>
    Los neumólogos pediátricos saben cómo examinar y tratar a los niños de un modo que los hace relajarse y cooperar.
    Si su hijo tiene dificultades respiratorias o un problema con los pulmones, un neumólogo pediátrico tiene la experiencia y la preparación para tratarlo.
    </p>`,
  },
  {
    title: "Nutrición",
    description: `<p>El nutriólogo es un profesional de la salud especializado en las características y propiedades químicas y biológicas de los alimentos, capacitado para realizar la evaluación y orientación de la alimentación saludable de las personas y grupos sociales.
    </p><p>Elaborar un plan de alimentación sano es la tarea del nutriólogo con su paciente. Por ello, desde sus bases teóricas y prácticas, analiza y evalúa el organismo, el metabolismo y su relación con los alimentos en cada individuo para ayudarle por medio de una dieta a alcanzar un mejor estado de salud.
    </p>`,
  },
  {
    title: "Neurología",
    description: `<p>La Neurología es la especialidad médica que trata los trastornos del sistema nervioso y que se ocupa de la prevención, diagnóstico, tratamiento, rehabilitación e investigación de las enfermedades neurológicas.
    </p><p>Bajo el término de enfermedad neurológica se engloba a todas aquellas enfermedades que afectan al sistema nervioso central (el cerebro y la médula espinal) y el sistema nervioso periférico (músculos y nervios).
    </p>`,
  },
  {
    title: "Neuropediatría",
    description: `<p>Un neurólogo infantil, es un médico que trata a los niños con problemas del sistema nervioso. Los problemas del sistema nervioso pueden empezar en el cerebro, en la columna, en los nervios o en los músculos. Esto puede conducir a problemas tales como <span class="underline">convulsiones</span>, <span class="underline">dolores de cabeza</span> o <span class="underline">retrasos del desarrollo</span>.</p>`,
  },
  {
    title: "Odontología",
    description: `<p>La odontología también denominada estomatología, es una especialidad de la Medicina que se ocupa de la prevención, el diagnóstico y tratamiento de las enfermedades que afectan a cualquier parte de la estructura mandibular:
    </p><ul><li>Dientes.</li>
    <li>Encías.</li>
    <li>Periodonto.</li>
    <li>Articulación temporomandibular.</li>
    <li>El conjunto del sistema muscular y nervioso.</li></ul>
    `,
  },
  {
    title: "Otorrinolaringología ",
    description: `<p>Es la especialidad médica que se encarga de la prevención, diagnóstico y tratamiento, tanto médico como quirúrgico, de las enfermedades de:
    </p><ol><li>El oído.</li>
    <li>Las vías aéreo-digestivas superiores: boca, nariz y senos paranasales, faringe y laringe.</li> 
    <li>Las estructuras de cabeza y cuello.</li></ol>
   <p>Asimismo, la cirugía traumatológica, oncológica, plástica, estética y reparadora de la cara y el cuello también pueden ser tratadas por el médico otorrinolaringólogo y especialista en cirugía de cabeza y cuello.</p>
   `,
  },
  {
    title: "Pediatría",
    description: `<p>La pediatría es la rama de la medicina que se especializa en la salud y las enfermedades de los niños. Se trata de una especialidad médica que se centra en los pacientes desde el momento del nacimiento hasta la adolescencia.</p>`,
  },
  {
    title: "Proctología",
    description: `<p>La proctología o coloproctología es una subespecialidad de cirugía general y del aparato digestivo que se ocupa del diagnóstico y tratamiento, sea quirúrgico o no, de todas las enfermedades que afecta al ano, el recto y el colon: hemorroides, fisura y fístula anal, abcesos rectales, estreñimiento, colitis ulcerosa, condilomas, incontinencia fecal, prolapso de recto, divertículos de colon, enfermedad inflamatoria intestinal, pólipos de colon y recto, cáncer colorrectal, etc. Asi mismo, los proctólogos son los encargados de realizar colonospopias, rectoscopias, defecografía, manometría anal, ostomías, etc.</p>`,
  },
  {
    title: "Psicología",
    description: `<p>Psicología. <span class="underline">Ciencia</span> que estudia la <span class="underline">vida</span> psíquica, sus funciones psicológicas como la <span class="underline">memoria</span>, el <span class="underline">pensamiento</span>, las <span class="underline">emociones</span>, los <span class="underline">instintos</span>, los <span class="underline">sueños</span>, el <span class="underline">lenguaje</span>, la <span class="underline">inteligencia</span> y la <span class="underline">percepción</span>; y también características del crecimiento y desarrollo del <span class="underline">hombre</span>, la <span class="underline">conducta</span>, la <span class="underline">motivación</span>, la <span class="underline">personalidad</span>, la <span class="underline">conciencia</span>, el inconsciente, las relaciones, el <span class="underline">aprendizaje</span>, la <span class="underline">educación</span>, el <span class="underline">trabajo</span>, la <span class="underline">cultura</span>, la <span class="underline">sociedad</span> y los nuevos aportes de la Psicología Transpersonal, que reconocen e investigan la dimensión trascendente del hombre.</p>`,
  },
  {
    title: "Psiquiatría",
    description: `<p>Es la ciencia que se dedica al estudio y tratamiento de las enfermedades mentales. Su objetivo es prevenir, diagnosticar, tratar y rehabilitar los trastornos de la mente.</p>`,
  },
  {
    title: "Ortopedia y Traumatología",
    description: `<p>Es la especialidad médica que se dedica al diagnóstico, tratamiento, rehabilitación y prevención de lesiones y enfermedades del sistema musculo esquelético del cuerpo humano. Este complejo sistema incluye los huesos, las articulaciones, los ligamentos, los tendones, los músculos y los nervios que le permiten a una persona moverse, trabajar y ser activa.</p>`,
  },
  {
    title: "Urología",
    description: `<p>La Urología es la especialidad médico-quirúrgica que investiga y estudia las afecciones, tanto médicas como quirúrgicas, del aparato urinario secretor y excretor de ambos sexos y del aparato genital masculino, glándulas suprarrenales, etc., y de todas las alteraciones derivadas de la patología de los mismos.</p>`,
  },
  {
    title: "Oftalmología",
    description: `<p>Especialidad que estudia las enfermedades de ojo y su tratamiento, incluyendo el globo ocular, su musculatura, el sistema lagrimal y los párpados.</p>`,
  },
]

export class especialidades extends Component {
  constructor(props) {
    super(props)

    this.state = {
      specialties: specialties,
      specialtiesFiltered: specialties,
    }
  }

  filterResults = e => {
    e.preventDefault()

    const text = e.target.value

    const newSpecialtiesFiltered = this.state.specialties.filter(specialty => {
      return (
        specialty.title.toLowerCase().includes(text.toLowerCase()) ||
        specialty.description.toLowerCase().includes(text.toLowerCase())
      )
    })

    this.setState({ specialtiesFiltered: newSpecialtiesFiltered })
  }
  render() {
    return (
      <div>
        <h3>
          <span className="black">Nuestras</span>Especialidades
        </h3>
        <div className="input-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              class="heroicon-ui"
              d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
            />
          </svg>
          <input
            type="search"
            onChange={this.filterResults}
            placeholder="Busca tu especialidad aquí"
          ></input>
        </div>
        <div>
          {this.state.specialtiesFiltered.map(specialty => {
            return <Especialidad {...specialty}></Especialidad>
          })}
        </div>
      </div>
    )
  }
}

export default especialidades
